<template>
	<div class="container">
		<div class="row">
			<div class="col">
				<h1>Items</h1>
				<Form @submit="searchBySearchword">
					<div class="input-group mb-3">
						<Field
							name="searchword"
							type="search"
							autocomplete="off"
							id="searchword"
							class="form-control"
							placeholder="Search"
							v-model="searchword"
							:rules="validateSearchRequired"
						/>
						<div class="input-group-append">
							<button class="btn btn-primary ms-3" type="button" @click="searchBySearchword">Search</button>
							<button class="btn btn-primary ms-3" type="button" @click="resetForm">X</button>
						</div>
					</div>
					<ErrorMessage name="searchword" class="error-feedback d-block alert alert-warning" />
				</Form>

				<ul class="list-group mb-3">
					<li class="list-group-item" v-for="(item, index) in displayedItems" :key="index">
						<router-link :to="'/item-edit/' + item.id" class="btn btn-primary badge badge-warning">Edit</router-link>
						{{ item.title }}
					</li>
				</ul>

				<div class="d-flex justify-content-between">
					<nav aria-label="Page navigation example">
						<ul class="pagination">
							<li class="page-item" :class="{disabled: page === 1}">
								<button type="button" class="page-link" @click="page--">
									<span aria-hidden="true">&laquo;</span>
								</button>
							</li>
							<li class="page-item" :class="{active: page === pageNumber}" v-for="(pageNumber, index) in pages" :key="index">
								<button type="button" class="page-link" @click="page = pageNumber">{{ pageNumber }}</button>
							</li>
							<li class="page-item" :class="{disabled: page >= pages.length}">
								<button type="button" class="page-link" @click="page++">
									<span aria-hidden="true">&raquo;</span>
								</button>
							</li>
						</ul>
					</nav>
					<div>
						<Field name="itemsPerPage" v-slot="{value}" v-model="perPage" as="select" class="form-select" aria-label="Icon">
							<option :selected="value == 5" value="5">5</option>
							<option :selected="value == 10" value="10">10</option>
							<option :selected="value == 25" value="25">25</option>
							<option :selected="value == 50" value="50">50</option>
						</Field>
					</div>
				</div>

				<p v-if="message" class="error-feedback d-block alert alert-warning mb-3">{{ message }}</p>
				<router-link class="btn btn-primary" to="/item-insert">New</router-link>
			</div>
		</div>
	</div>

	<div class="d-block mt-5 pt-5"></div>
</template>

<script>
import ItemService from '../services/item.service';
import {Form, Field, ErrorMessage} from 'vee-validate';

export default {
	name: 'AppItems',
	components: {
		Form,
		Field,
		ErrorMessage,
	},
	data() {
		return {
			items: [],
			searchword: '',
			message: '',
			editProgess: false,
			page: 1,
			perPage: 10,
			pages: [],
		};
	},
	methods: {
		resetForm() {
			this.message = '';
			this.searchword = '';
			this.retrieveItems();
		},
		validateSearchRequired(value) {
			if (value && value.trim()) {
				if (value.length > 3) {
					return true;
				} else {
					this.message = '';
					return 'Min 3 chars';
				}
			}
			return true;
		},
		retrieveItems() {
			this.editProgess = true;
			ItemService.getAll()
				.then((response) => {
					// Check if success, if not show error message
					if (!response.data.error) {
						this.items = response.data.result;
					} else {
						this.items = [];
						this.message = response.data.error.message;
					}
					this.editProgess = false;
				})
				.catch((e) => {
					this.catchError(e);
				});
		},
		searchBySearchword() {
			this.message = '';
			this.editProgess = true;
			ItemService.search(this.searchword)
				.then((response) => {
					// Check if success, if not show error message
					if (!response.data.error) {
						this.items = response.data.result;
					} else {
						this.items = [];
						this.message = response.data.error.message;
					}
					this.editProgess = false;
				})
				.catch((e) => {
					this.catchError(e);
				});
		},
		setPages() {
			this.pages = [];
			let numberOfPages = Math.ceil(this.items.length / this.perPage);
			for (let index = 1; index <= numberOfPages; index++) {
				this.pages.push(index);
			}
		},
		paginate(i) {
			let page = this.page;
			let perPage = this.perPage;
			let from = page * perPage - perPage;
			let to = page * perPage;
			return i.slice(from, to);
		},
		catchError(e) {
			// Check if not authorized, if not show error message
			if (e.response.status === 401) {
				this.$store.dispatch('auth/logout');
				this.$router.push('/login');
			} else {
				this.editProgess = false;
			}
		},
	},
	computed: {
		displayedItems() {
			return this.paginate(this.items);
		},
	},
	watch: {
		items() {
			this.setPages();
		},
		perPage() {
			this.setPages();
			localStorage.setItem('perPage', JSON.stringify(this.perPage));
		},
	},
	created() {
		this.perPage = JSON.parse(localStorage.getItem('perPage')) || 10;
		this.retrieveItems();
	},
	mounted() {},
};
</script>
